<div class="body-wrapper">
  <!--============================
  =            Header            =
  =============================-->
  <section class="bg-white header-border">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg  navigation">
            <a [routerLink]="['/home']" class="navbar-brand" >
              <img src="assets/img/logo/buyersclubhq.png" alt="buyersclubhq" class="home-logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto main-nav ">
                <li class="nav-item dropdown dropdown-slide">
                  <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Categories <span><i class="fa fa-angle-down"></i></span>
                  </a>
                  <!-- Dropdown list -->
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" [routerLink]="['/category']" [queryParams]="{type:'401k'}" >EMPLOYEE RETIREMENT PLANS</a>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/about']">About</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/blog']" >Resources</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/contact']">Contact</a>
                </li>
                <!--<li class="nav-item">
                  <a class="nav-link" href="dashboard.html">Dashboard</a>
                </li>-->
                <!--<li class="nav-item dropdown dropdown-slide">
                  <a class="nav-link dropdown-toggle" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Listing <span><i class="fa fa-angle-down"></i></span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </div>
                </li>-->
              </ul>
              <button [routerLink]="['/signin']" class="btn btn-lg btn-light top-bar-btns" ><i class="fa fa-user-circle-o fa-sm"></i>&nbsp;&nbsp;Login</button>
              <button (click)="goToSignUp()" class="btn btn-lg btn-primary top-bar-btns" ><i class="fa fa-group fa-sm"></i>&nbsp;&nbsp;Join</button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <!--============================
  =        End Header            =
  =============================-->
  <router-outlet></router-outlet>
  <!--============================
  =            Footer            =
  =============================-->
  <footer class="footer section section-sm">
    <!-- Container Start -->
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-7 offset-md-1 offset-lg-0">
          <!-- About -->
          <div class="block about">
            <!-- footer logo -->
            <h2 class="text-white">Buyers Club HQ</h2>
            <!-- description -->
            <p class="alt-color">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
        <!-- Link list -->
        <div class="col-lg-2 offset-lg-1 col-md-3">
          <div class="block">
            <h4>Site Pages</h4>
            <ul>
              <li><a [routerLink]="['/about']">About</a></li>
              <li><a class="click" >How It Works</a></li>
              <li><a [routerLink]="['/contact']" >Contact</a></li>
              <li><a [routerLink]="['/blog']">Resources</a></li>
              <li><a class="click" >Terms of Services</a></li>
            </ul>
          </div>
        </div>
        <!-- Link list -->
        <div class="col-lg-2 col-md-3 offset-md-1 offset-lg-0">
          <div class="block">
            <h4>Member Pages</h4>
            <ul>
              <li><a class="click" [routerLink]="['/signin']" >Login</a></li>
              <li><a class="click" (click)="goToSignUp()" >Join the Club</a></li>
              <li><a class="click" >Terms of Services</a></li>
            </ul>
          </div>
        </div>
        <!-- Promotion -->
        <div class="col-lg-4 col-md-7">
          <!-- App promotion -->
          <div class="block-2 app-promotion">
            <a href="mailto:customercare@buyersclubhq.com">
              <!-- Icon -->
              <i class="fa fa-envelope fa-2x"></i>
            </a>
            <p><a href="mailto:customercare@buyersclubhq.com">customercare@buyersclubhq.com</a></p>
          </div>
        </div>
      </div>
    </div>
    <!-- Container End -->
  </footer>
  <!-- Footer Bottom -->
  <footer class="footer-bottom">
      <!-- Container Start -->
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-12">
            <!-- Copyright -->
            
            <div class="copyright">
                <a [routerLink]="['/home']" class="navbar-brand" >
                  <img src="assets/img/logo/buyersclubhq.png" alt="buyersclubhq" class="home-logo-footer">
                </a>
                <span>Copyright © 2018. All Rights Reserved</span>
                
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <!-- Social Icons -->
            <ul class="social-media-icons text-right">
                <li><a class="fa fa-facebook" href=""></a></li>
                <li><a class="fa fa-twitter" href=""></a></li>
                <li><a class="fa fa-pinterest-p" href=""></a></li>
                <li><a class="fa fa-vimeo" href=""></a></li>
              </ul>
          </div>
        </div>
      </div>
      <!-- Container End -->
      <!-- To Top -->
      <div class="top-to">
        <a id="top" class="" (click)="top()"><i class="fa fa-angle-up"></i></a>
      </div>
  </footer>
  <!--============================
  =        End Footer            =
  =============================-->  
</div>