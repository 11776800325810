import {Component, OnDestroy, Inject} from '@angular/core';
import { navItems } from './../../_nav';
import { APP_CONFIG, AppConfig } from '../../app-config.module';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {


  }

  ngOnDestroy(): void {
 
  }

  goToSignUp() {
    window.open(this.config.signUpEndpoint, "_self");
  }

  top() {
    try { 
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } catch (e) {
      window.scrollTo(0, 0);
    }
  }
}
