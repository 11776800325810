import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BlogService {
    
  constructor(private http: HttpClient) {

  }

  getConfig(url): Promise<any> {
    return new Promise(resolve => {
      return this.http.get(url)
      .subscribe(data => {
        resolve(data);
      });
    });
  }
}
